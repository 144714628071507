<template>
  <el-cascader
    :disabled="disabled"
    :style="{ width: width }"
    size="small"
    :options="deptTree"
    :props="cascProps"
    clearable
    filterable
    placeholder="请选择"
    v-model="resultValue"
  ></el-cascader>
</template>

<script>
import { getAreaTree } from './api'
export default {
  name: 'SelectDept',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    resultValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      deptTree: [],
      cascProps: {
        label: 'name',
        value: 'name',
        checkStrictly: false,
        expandTrigger: 'hover',
        emitPath: true,
        multiple: false,
        leaf: 'leaf'
      }
    }
  },

  created() {
    this.getTree()
  },

  methods: {
    async getTree() {
      let res = await getAreaTree()
      this.deptTree = this.setLeaf(res)
    },

    setLeaf(list) {
      return list.map((item) => {
        if (item.children && item.children.length > 0) item.children = this.setLeaf(item.children)
        else {
          item.leaf = true
          delete item.children
        }
        return item
      })
    }
  }
}
</script>

<style></style>
