<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="150px" :rules="rules">
      <el-form-item label="店铺名称" prop="name">
        <el-input style="width: 250px" size="mini" v-model="formData.name" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="商户分类" prop="categoryId">
        <SelectShopCategoryType v-model="formData.categoryId" width="250px"></SelectShopCategoryType>
      </el-form-item>
      <el-form-item label="奖励比例(百分比)" prop="rewardRatio">
        <el-input-number style="width: 250px" size="mini" :precision="2" :step="1" :min="0.01" v-model="formData.rewardRatio" clearable placeholder="请输入奖励比例"></el-input-number>
      </el-form-item>
      <el-form-item label="营业时间">
        <el-input style="width: 250px" size="mini" v-model="formData.businessStr" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="商家封面图">
          <UploadImg :url.sync="formData.cover"></UploadImg>
        </el-form-item>
      <el-form-item label="商家图片">
        <UploadImgs :background_images.sync="formData.images"></UploadImgs>
      </el-form-item>
      <el-form-item label="店铺地址" prop="address">
        <div class="position">
          <div class="input">
            <AreaTree v-model="addressDesc" width="200px" :address="keyword"></AreaTree>
            <el-input
              style="width: 200px; margin-left: 20px"
              v-model="formData.address"
              size="small"
              placeholder="请输入详细地址"
              clearable
            ></el-input>
            <span class="tip">（注意：输入详细地址后点击地图确定坐标！）</span>
          </div>
          <div class="map">
            <Map :lng.sync="formData.longitude" :lat.sync="formData.latitude" :addressDesc="addressDesc" :address="formData.address"></Map>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input style="width: 250px" size="mini" v-model="formData.contact" clearable placeholder="请输入"></el-input>
      </el-form-item>
        <el-form-item label="商家寄语">
        <el-input  type="textarea" maxlength="500" style="width: 250px" size="mini" v-model="formData.shopMsg" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="" size="small" @click="$router.back()">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import UploadImgs from '@/components/qiniu-uploader/uploader-imgs.vue'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
import SelectShopCategoryType from '@/views/td/shop-category/select-shop-category.vue'
import Map from '@/components/map/map-dsc.vue'
import AreaTree from '@/components/select-area/select-area.vue'

export default {
  name: 'Update',
  components: { UploadImgs, UploadImg, SelectShopCategoryType, Map, AreaTree },
  data() {
    return {
      formData: {
        name: '',
        categoryId: null,
        images: [],
        address: '',
        contact: '',
        shopMsg: '',
        cover: '',
        businessStr: '',
        rewardRatio: 5.00,
        latitude: '',
        longitude: '',
        province: '',
        city: '',
        district: '',
      },
      addressDesc: [],
      rules: {
        name: [
          { trigger: 'blur', message: '店铺名称必填', required: true }
        ],
        categoryId: [
          { trigger: 'blur', message: '店铺分类必选', required: true }
        ],
        address: [
          { trigger: 'blur', message: '店铺地址必填', required: true }
        ],
        rewardRatio: [
          { trigger: 'blur', message: '奖励比例必填', required: true }
        ]
      },
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  computed: {
    keyword() {
      return this.formData.address
    }
  },
  methods: {
    save() {
      this.formData.images = this.formData.images.map((item) => item.url)

      this.formData.province = this.addressDesc[0] || ''
      this.formData.city = this.addressDesc[1] || ''
      this.formData.district = this.addressDesc[2] || ''
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      const res = await getDetailAPI(this.$route.params.id)
      this.formData = res
      this.formData.images = this.formData.images.map((item) => {
        return {
          url: item
        }
      })
      const { province, city, district } = res
      if (province) {
        this.addressDesc.push(province)
      }
      if (city) {
        this.addressDesc.push(city)
      }
      if (district) {
        this.addressDesc.push(district)
      }
    }
  }
}
</script>
